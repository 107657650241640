import { useEffect, useState } from 'react';
import styled from 'styled-components'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import east from "../assets/east.jpg"
import west from "../assets/west.jpeg"
import disney from "../assets/disney.jpg"
import malaysia from '../assets/malaysia.jpg'
import singapore from '../assets/singapur.jpg'
import unis from "../assets/unis.jpg"
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function Trips() {
    const [spacing, setSpacing] = useState(7);

    const trips = [
        {
            place: "East Coast-NY,Niagara falls, Boston-Washington",
            image: east
        },
        {
            place: "West Coast-LA, Silicon Valley",
            image: west
        },
        {
            place: "University Tour, Stanford, MIT, Harvard, Columbia",
            image: unis
        },
        {
            place: "Disneyland and Universal Studios",
            image: 'https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,h_1200,q_75,w_1200/v1/clients/anaheimca/0910ZR_0029CR_R3_RTD_51578b3c-f4b9-4b12-916b-56591be853d7.jpg'
        },
        {
            place: "South-east Asia, Singapore",
            image: singapore
        },
        {
            place: "Malaysia, Kuala-Lumpur",
            image: malaysia
        },

    ]
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Wrapper className='section' >
            <Box sx={{ flexGrow: 1 }}>
                <div style={{ padding: "10px", justifyContent: "center", display: "flex", alignItems: "center" }}>

                    <h4 >Enjoy unforgettable trips with Bilimtech</h4>
                </div>
                <Grid sx={{ flexGrow: 1, my: 3 }} container spacing={2}>
                    <Grid item xs={12} >
                        <Grid container justifyContent="center" spacing={spacing}>
                            {trips.map((item, ind) => (
                                <Grid key={ind} item>
                                    <Card sx={{ maxWidth: 345 }} >

                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image={item.image}
                                            alt={item.place}
                                        />
                                        <CardContent>
                                            <Typography variant="body1" color="text.primary">
                                                {item.place}
                                            </Typography>
                                        </CardContent>


                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                </Grid>
            </Box>


        </Wrapper>

    );
}


const Wrapper = styled.section`
  background: var(--clr-grey-10);
  .featured {
    margin: 4rem auto;
    display: grid;
    gap: 2.5rem;
    img {
      height: 225px;
    }
  }
  .btn {
    display: block;
    width: 148px;
    margin: 0 auto;
    text-align: center;
  }
  @media (min-width: 576px) {
    .featured {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
`