import * as React from 'react';
import styled from 'styled-components'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import owl from "../assets/owl.jpg"
import coper from '../assets/cop.png'
import amo from '../assets/amo.png'
import sasmo from '../assets/sasmo.png'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { isMobile } from 'react-device-detect';

export default function Olypiad() {
    const [spacing, setSpacing] = React.useState(7);

    const trips = [
        {
            place: "SASMO",
            image: sasmo
        },
        {
            place: "AMO",
            image: amo
        },
        {
            place: "Copernicus",
            image: coper
        },
        {
            place: "Owlypia",
            image: owl
        },

    ]
    return (
        <Wrapper className='section' style={{ paddingTop: "40px" }} >
            <Box sx={{ flexGrow: 1 }}>
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12} >
                        <Grid container justifyContent="center" spacing={spacing}>
                            {trips.map((item, ind) => (
                                <Grid key={ind} item>
                                    {isMobile ? <Card sx={{ width: 350 }} style={{ borderRadius: "3%" }} >

                                        <CardMedia
                                            component="img"
                                            height="280"
                                            image={item.image}
                                            alt={item.place}
                                            sx={{ padding: "1em 1em 1em 1em", objectFit: "contain" }}
                                        />
                                    </Card> : <Card sx={{ maxWidth: 240 }} style={{ borderRadius: "3%" }} >

                                        <CardMedia
                                            component="img"
                                            height="280"
                                            image={item.image}
                                            alt={item.place}
                                            sx={{ padding: "1em 1em 1em 1em", objectFit: "contain" }}
                                        />
                                    </Card>}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                </Grid>
            </Box>
            <div className='title'>
                <h2 id='subjects' style={{ marginTop: "50px" }}>Subjects</h2>
                <div className='underline'></div>
            </div>


        </Wrapper>

    );
}


const Wrapper = styled.section`
  background: var(--clr-grey-10);
  .featured {
    margin: 2rem auto;
    display: grid;
    gap: 2.5rem;
    img {
      height: 225px;
    }
  }
  .btn {
    display: block;
    width: 148px;
    margin: 0 auto;
    text-align: center;
  }
  @media (min-width: 576px) {
    .featured {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
`