import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Sidebar, Footer } from './components';
import {
  Home,
  SingleProduct,
  Cart,
  Checkout,
  Error,
  About,
  Products,
  PrivateRoute,
  AuthWrapper,

} from './pages';
import SignUp from "./pages/signUp"
import Trips from "./pages/trips"
import CustomImageList from "./components/countryGallery"
function App() {
  return (
    <AuthWrapper>
      <Router>
        <Navbar />
        <Sidebar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='cartss' element={<Cart />} />
          <Route path='productss' element={<Products />} />
          <Route path='/register' element={<SignUp />} />
          <Route path='/trips' element={<Trips />} />
          <Route path='/countries' element={<CustomImageList />} />
          <Route path='productss/:id' element={<SingleProduct />} />
          <Route
            path='checkoutt'
            element={
              <PrivateRoute>
                <Checkout />
              </PrivateRoute>
            }
          />
          <Route path='*' element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </AuthWrapper>
  );
}

export default App;
