import { useEffect } from 'react'
import SocialMedia from "./socialMedia"
import { FeaturedProducts, Hero, Services, Contact } from '../components'
import Testimonials from "./Testimonials"
import { isMobile } from 'react-device-detect';
import Carousel from "../components/carousel"
import Olympiad from '../components/olympiads'
const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <main>
      {isMobile ? <Hero /> : <Carousel />}
      <FeaturedProducts />
      <Olympiad />
      <Services />
      <Testimonials />
      <SocialMedia />
      <Contact />
    </main>
  )
}

export default HomePage
