import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo.svg'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { links } from '../utils/constants'
import CartButtons from './CartButtons'
import { useProductsContext } from '../context/products_context'
import { useUserContext } from '../context/user_context'
import { useNavigate } from 'react-router-dom'
const Nav = () => {
  const { openSidebar } = useProductsContext()
  const { myUser } = useUserContext()
  const navigate = useNavigate()
  const handleClickScroll = (id, url) => {
    if (typeof id === 'string' && url !== '/trips') {
      const element = document.getElementById(id);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      } else {

        navigate('/')

      }
    } else {
      navigate(url)
    }
  };
  return (
    <div style={{ position: "sticky", top: "0", zIndex: "100", background: "white", overflowX: "hidden" }} >

      <NavContainer>
        <div className='nav-center' >
          <div className='nav-header'>
            <Link to='/'>
              {/* <img src={logo} alt='comfy sloth' /> */}
              <h4 style={{ color: "#102A42" }}>bilimtech   </h4>

            </Link>
            <button type='button' className='nav-toggle' onClick={openSidebar}>
              <FaBars />
            </button>
          </div>
          <ul className='nav-links'>
            {links.map((link) => {
              const { id, text, url } = link
              return (
                <li key={id}>
                  <p to={url} onClick={() => handleClickScroll(id, url)}>{text}</p>
                </li>
              )
            })}
            {myUser && (
              <li>
                <Link to='/checkout'>checkout</Link>
              </li>
            )}
          </ul>
          <CartButtons />
        </div>
      </NavContainer>
    </div >
  )
}

const NavContainer = styled.nav`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  

  .nav-center {
    width: 90vw;
    margin: 0 auto;

    max-width: var(--max-width);
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 175px;
      margin-left: -15px;
    }
  }
  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
  }
  .cart-btn-wrapper {
    display: none;
  }
  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      p {
        color: var(--clr-grey-3);
        font-size: 1rem;
        cursor:pointer;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid var(--clr-primary-7);
        }
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
`

export default Nav
