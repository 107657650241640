import React from 'react'
import styled from 'styled-components'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import BusinessIcon from '@mui/icons-material/Business';
import './contact.css'
const Contact = () => {
  return (

    <div className="body">


      <div className="container">
        <h3>Contact us</h3>
        <div className="content">
          <div className="left-side">
            <div className="address details">
              <div className="text-one"> <BusinessIcon /> Bishkek</div>
            </div>
            <div className="phone details">

              <div className="text-one"><PhonelinkRingIcon /> +996 778 163 837</div>
            </div>
            <div className="phone details">

              <div className="text-one"><MarkEmailReadIcon /> Email</div>
              <div className="text-one"> info.bilimtech@gmail.com</div>
              <div className="text-one">  info@bilimtech.org</div>
            </div>

          </div>
          <div className="right-side">
            <div className="topic-text">Send us a message</div>
            <form action="#">
              <div className="input-box">
                <input type="text" placeholder="Enter your name" />
              </div>
              <div className="input-box">
                <input type="text" placeholder="Enter your email" />
              </div>
              <div className="input-box message-box">
                <textarea placeholder="Enter your message"></textarea>
              </div>
              <div className="button">
                <input type="button" value="Send Now" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}
const Wrapper = styled.section`
  padding: 5rem 0;
  h3 {
    text-transform: none;
  }
  p {
    line-height: 2;
    max-width: 45em;
    color: var(--clr-grey-5);
  }
  .contact-form {
    width: 90vw;
    max-width: 500px;
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .form-input,
  .submit-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: 2px solid var(--clr-black);
  }
  .form-input {
    border-right: none;
    color: var(--clr-grey-3);
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
  }
  .submit-btn {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
  }
  .form-input::placeholder {
    color: var(--clr-black);
    text-transform: capitalize;
  }
  .submit-btn {
    background: var(--clr-primary-5);
    text-transform: capitalize;
    letter-spacing: var(--spacing);
    cursor: pointer;
    transition: var(--transition);
    color: var(--clr-black);
  }
  .submit-btn:hover {
    color: var(--clr-white);
  }
  @media (min-width: 992px) {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 8rem;
      margin-top: 2rem;
    }
    p {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1280px) {
    padding: 15rem 0;
  }
`

export default Contact
