import React from 'react'
import { GiCompass, GiDiamondHard, GiStabbedNote } from 'react-icons/gi'
import CodeIcon from '@mui/icons-material/Code';
export const links = [
  {
    id: 1,
    text: 'home',
    url: '/',

  },
  {
    id: 2,
    text: 'about',
    url: '/about',
  },
  {
    id: 'subjects',
    text: 'subjects',
    url: '/',
  },
  {
    id: 'rounds',
    text: 'rounds',
    url: '/',
  },
  {
    id: 'trips',
    text: 'trips',
    url: '/trips',
  },
]

export const services = [
  {
    id: 1,
    icon: <GiCompass />,
    title: 'Math',
    text:
      'General Math for grades 2 to 11. This presents participants with a series of challenging mathematical problems that often require creative and non-standard approaches to solve. It cover a wide range of mathematical topics, including algebra, geometry, number theory.',
  },
  {
    id: 2,
    icon: <CodeIcon />,
    title: 'Coding',
    text:
      'Solution oriented coding competition for grades 8 to 11. Students challenge with various types of problems to find solutions using programmning languages.',
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: 'Art',
    text:
      'Art competitions for grades 2-11. Self-portraits, landscape painting, art-science, ecology, graffiti and illustrations are among examples of art competition.',
  },
]


export const mainPage = [
  {
    title: "Lorem ipsum dolor, sit amet consectetur",
    para: "Lorem ipsum i beatae tempore, non dolore sit nostrum quae? Cum.",
    link: "Visit for more...",
    image1: '../assets/images2.png',
    image: ''
  },
  {
    title: "Lorem ipsum dolor, sit amet consectetur",
    para: "Lorem ipsum i beatae tempore, non dolore sit nostrum quae? Cum.",
    link: "Click us for more...",
    image1: '../assets/ol1.jpg',
    image: ''
  },
  {
    title: "Lorem ipsum dolor, sit amet consectetur",
    para: "Lorem ipsum i beatae tempore, non dolore sit nostrum quae? Cum.",
    link: "Visit for more...",
    image1: '../assets/ol2.jpg',
    image: ''
  },
  {
    title: "Lorem ipsum dolor, sit amet consectetur",
    para: "Lorem ipsum i beatae tempore, non dolore sit nostrum quae? Cum.",
    link: "Visit for more...",
    image1: '../assets/sm7 (1).jpg',
    image: ''
  },
]

export const products_url = 'https://course-api.com/react-store-products'

export const single_product_url = `https://course-api.com/react-store-single-product?id=`
