import React from 'react'
import { useProductsContext } from '../context/products_context'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Error from '../components/Error'
import Loading from '../components/Loading'
import Product from '../components/Product'
import math1 from "../assets/ol1.jpg"
import math2 from "../assets/ol2.jpg"
import math3 from "../assets/ol3.jpg"
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import ResponsiveGrid from './mediacontent'

const SocialMedia = () => {
  const {
    products_loading: loading,
    products_error: error,
    featured_products: featured,
  } = useProductsContext()
  // if (loading) {
  //     return <Loading />
  // }
  // if (error) {
  //     return <Error />
  // }
  const openNewTab = () => {
    window.open('https://www.instagram.com/bilimtech_kg/', '_blank'); // Replace with the URL you want to open
  };
  return (
    <Wrapper className='section'>
      <div className='title'>
        <h2>Find us on...</h2>
        <div className='underline'></div>
      </div>
      <div className='section-center'>
        <Container maxWidth="md" sx={{ mt: 5 }}>

          <Stack justifyContent="center" direction="row" alignItems="center" textAlign='center' spacing={4} >
            <FacebookIcon sx={{ cursor: "pointer" }} onClick={openNewTab} color="primary" fontSize="large" />
            <InstagramIcon onClick={openNewTab} sx={{ color: "#E1306C", cursor: "pointer" }} fontSize="large" />
            <LinkedInIcon color="primary" fontSize="large" />
            <YouTubeIcon fontSize="large" sx={{ color: "#FF0000" }} />
            <PinterestIcon fontSize="large" sx={{ color: "#E60023" }} />
            <TwitterIcon fontSize="large" sx={{ color: "#1DA1F2" }} />

          </Stack>
          <div style={{ marginTop: "30px" }}></div>
          <ResponsiveGrid />
        </Container>

      </div>


    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-grey-10);
  .featured {
    margin: 4rem auto;
    display: grid;
    gap: 2.5rem;
    img {
      height: 225px;
    }
  }
  .btn {
    display: block;
    width: 148px;
    margin: 0 auto;
    text-align: center;
  }
  @media (min-width: 576px) {
    .featured {
      grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    }
  }
`

export default SocialMedia
