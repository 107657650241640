import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom"
import { PageHero } from '../components'
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CheckIcon from '@mui/icons-material/Check';
const AboutPage = () => {
  const navigate = useNavigate()
  const handleClick = (country) => {
    if (country === 's') {
      navigate('/countries')
    }
    if (country === 'u') {
      navigate('/countries')
    }
    if (country === 'e') {
      navigate('/countries')
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <main>
      <PageHero title='about' />
      <Wrapper className='page section section-center' style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
        <article>
          <div className='title'>
            <h2>Why Participate in Our Contest?</h2>
            <div className='underline'></div>
          </div>
          <p>
            <strong>BilimTech: Shaping the Minds of Tomorrow, Exploring the World Today. <br /></strong>

            For the past five years,
            BilimTech has been at the forefront of educational innovation, providing students with a unique platform to hone their skills, compete, and explore the world. Our company's mission is to foster intellectual growth and global awareness among students by organizing successful competitions and unforgettable international travel experiences.
            These competitions cover a diverse array of subjects, from mathematics and science to literature and technology, catering to a wide spectrum of interests and talents.
            We have been exclusive partner of various international olympiads from multiple countries.
          </p>
          <ul style={{ color: 'teal', fontSize: "20px", cursor: "pointer" }}>
            <li style={{ marginBottom: "2px" }} onClick={() => handleClick("s")}>

              <TravelExploreIcon />  Singapore
            </li>
            <li style={{ marginBottom: "2px" }} onClick={() => handleClick("s")}>

              <TravelExploreIcon />  USA
            </li>
            <li onClick={() => handleClick("e")}>

              <TravelExploreIcon />  England
            </li>
          </ul>
          including local.
          <p>

            <CheckIcon /> <strong>Intellectual Challenge:</strong> Contest questions are carefully crafted to test your problem-solving skills and stimulate your analytical thinking. You'll face problems that require creative solutions, pushing your boundaries and expanding your horizons. <br />

            <CheckIcon /> <strong> Recognition:</strong> Competing in our contest provides an excellent opportunity to showcase your talent and dedication. Win recognition among your peers, teachers, and potential educational institutions abroad.<br />

            <CheckIcon /> <strong>Prizes and Scholarships:</strong> We offer attractive prizes and scholarships to top performers. Your hard work could translate into tangible rewards for your academic journey.<br />

            <CheckIcon /> <strong>Skill Enhancement:</strong>  Participation in our contest is an excellent way to sharpen your skills. It's not just about winning; it's about the journey of improvement.<br />

            <CheckIcon /> <strong>Networking:</strong>  Connect with fellow enthusiasts from around the world. Share ideas, strategies, and passion for STEM. Build a network of like-minded individuals who share your love for this subject.
          </p>
        </article>
      </Wrapper>
    </main>
  )
}
const Wrapper = styled.section`
  display: grid;
  gap: 4rem;
  img {
    width: 100%;
    display: block;
    border-radius: var(--radius);
    height: 500px;
    object-fit: cover;
  }
  p {
    line-height: 2;
    max-width: 45em;
    margin: 0 auto;
    margin-top: 2rem;
    color: var(--clr-grey-5);
  }
  .title {
    text-align: left;
  }
  .underline {
    margin-left: 0;
  }
  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`
export default AboutPage
