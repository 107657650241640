import * as React from 'react';
import { useNavigate } from "react-router-dom"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DOB from "./birthDate"
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://bilimtech.org/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {
    const navigate = useNavigate()
    const [age, setAge] = React.useState('');
    const [msg, setMessage] = React.useState('');
    const [data, setData] = React.useState(
        {
            firstName: "",
            lastName: "",
            email: "",
            file: "",
            gender: "",
            day: "",
            month: "",
            year: "",
            subject: "",
            schoolName: "",
            teacher: "",
            teacherEmail: "",
            code: "",
            mobileCode: "",
            number: ""
        },

    )

    // const handleChange = (event) => {
    //     setAge(event.target.value);
    // };
    const handleSubmit = (event) => {
        event.preventDefault();

        for (let i in data) {
            if (!data[i]) {
                setMessage("please fill out all required fields")
                return
            }
        }
        setTimeout(() => {
            setMessage("")
            navigate('/')
        }, 2000)
        setMessage("thanks for submitting")

    };
    const handleInput = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <AppRegistrationIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Please fill out registration form
                    </Typography>
                    <Box style={{ color: "black" }} component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                    onChange={handleInput}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                    onChange={handleInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    onChange={handleInput}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="file"
                                    // label="upload"
                                    type="file"
                                    id="file"
                                    onChange={handleInput}
                                // autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl required>
                                    <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>Gender</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="gender"
                                        onChange={handleInput}
                                    >
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>

                                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>Date of Birth</FormLabel>
                                <div>
                                    <FormControl sx={{ minWidth: 100 }} required >
                                        <InputLabel id="demo-simple-select-disabled-label">Day</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-disabled-label"
                                            id="demo-simple-select-disabled"
                                            // value={age}
                                            label="Age"
                                            // onChange={handleChange}
                                            name='day'
                                            onChange={handleInput}
                                        >

                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={6}>6</MenuItem>
                                            <MenuItem value={7}>7</MenuItem>
                                            <MenuItem value={8}>8</MenuItem>
                                            <MenuItem value={9}>9</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={11}>11</MenuItem>
                                            <MenuItem value={12}>12</MenuItem>
                                            <MenuItem value={13}>13</MenuItem>
                                            <MenuItem value={14}>14</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={16}>16</MenuItem>
                                            <MenuItem value={17}>17</MenuItem>
                                            <MenuItem value={18}>18</MenuItem>
                                            <MenuItem value={19}>19</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={21}>21</MenuItem>
                                            <MenuItem value={22}>22</MenuItem>
                                            <MenuItem value={23}>23</MenuItem>
                                            <MenuItem value={24}>24</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={26}>26</MenuItem>
                                            <MenuItem value={27}>27</MenuItem>
                                            <MenuItem value={28}>28</MenuItem>
                                            <MenuItem value={29}>29</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={31}>31</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ mx: 1, minWidth: 120 }} required>
                                        <InputLabel id="demo-simple-select-error-label">Month</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-error-label"
                                            id="demo-simple-select-error"
                                            // value={age}
                                            label="Month"
                                            name='month'
                                            onChange={handleInput}
                                        >

                                            <MenuItem value={1}>January</MenuItem>
                                            <MenuItem value={2}>February</MenuItem>
                                            <MenuItem value={3}>March</MenuItem>
                                            <MenuItem value={4}>April</MenuItem>
                                            <MenuItem value={5}>May</MenuItem>
                                            <MenuItem value={6}>June</MenuItem>
                                            <MenuItem value={7}>July</MenuItem>
                                            <MenuItem value={8}>August</MenuItem>
                                            <MenuItem value={9}>September</MenuItem>
                                            <MenuItem value={10}>October</MenuItem>
                                            <MenuItem value={11}>November</MenuItem>
                                            <MenuItem value={12}>December</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ minWidth: 120 }} required>
                                        <InputLabel id="demo-simple-select-readonly-label">Year</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-readonly-label"
                                            id="demo-simple-select-readonly"
                                            // value={age}
                                            label="Year"
                                            name='year'
                                            onChange={handleInput}
                                        >
                                            <MenuItem value={2006}>2006</MenuItem>
                                            <MenuItem value={2007}>2007</MenuItem>
                                            <MenuItem value={2008}>2008</MenuItem>
                                            <MenuItem value={2009}>2009</MenuItem>
                                            <MenuItem value={2010}>2010</MenuItem>
                                            <MenuItem value={2011}>2011</MenuItem>
                                            <MenuItem value={2012}>2012</MenuItem>
                                            <MenuItem value={2013}>2013</MenuItem>
                                            <MenuItem value={2014}>2014</MenuItem>
                                            <MenuItem value={2015}>2015</MenuItem>
                                            <MenuItem value={2016}>2016</MenuItem>
                                            <MenuItem value={2017}>2017</MenuItem>
                                            <MenuItem value={2018}>2018</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Grid item xs={12}>
                                        <FormControl required sx={{ my: 2 }}>
                                            <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>Select your subject</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="subject"
                                                onChange={handleInput}
                                            >
                                                <FormControlLabel value="math" control={<Radio />} label="Math" />
                                                <FormControlLabel value="art" control={<Radio />} label="Art" />
                                                <FormControlLabel value="robotics" control={<Radio />} label="Robotics" />
                                                <FormControlLabel value="coding" control={<Radio />} label="Coding" />

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sx={{ my: 2 }}>
                                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>Enter your school name</FormLabel>
                                        <TextField
                                            // autoComplete="given-name"
                                            style={{ margin: "10px 0" }}
                                            name="schoolName"
                                            required
                                            fullWidth
                                            id="schoolName"
                                            label="School name"
                                            onChange={handleInput}
                                        // autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ my: 2 }} >
                                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black", marginBottom: "20px" }}>Teacher's/Ambassador's Name & Surname</FormLabel>
                                        <TextField
                                            style={{ margin: "10px 0" }}
                                            // autoComplete="given-name"
                                            name="teacher"
                                            required
                                            fullWidth
                                            id="teacher"
                                            onChange={handleInput}
                                            label="Teacher's/Ambassador's Name & Surname"
                                        // autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ my: 2 }}>
                                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>Teacher's/Ambassador's E-mail</FormLabel>
                                        <TextField
                                            style={{ margin: "10px 0" }}
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="teacherEmail"
                                            autoComplete="email"
                                            onChange={handleInput}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ my: 2 }} >
                                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>Teacher's/Ambassador's Mobile Number (WhatsApp)</FormLabel>
                                        <br />
                                        <Box
                                            component="form"
                                            sx={{
                                                '& > :not(style)': { my: 1, width: '12ch' },
                                            }}
                                            // noValidate
                                            autoComplete="off"
                                        >
                                            <TextField

                                                id="outlined-helperText"
                                                label="+1 or +996"
                                                helperText="Country Code"
                                                required
                                                name="code"
                                                onChange={handleInput}
                                            />
                                            <TextField
                                                id="outlined-helperText"
                                                label="550"
                                                helperText="Mobile Prefix"
                                                sx={{ mx: 1 }}
                                                required
                                                name="mobileCode"
                                                onChange={handleInput}
                                            />
                                            <TextField
                                                id="outlined-helperText"
                                                label="234566"
                                                helperText="Phone number"
                                                required
                                                name="number"
                                                onChange={handleInput}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sx={{ my: 2 }}>
                                        <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "black" }}>Enter Access Code</FormLabel>
                                        <TextField
                                            // autoComplete="given-name"
                                            style={{ margin: "10px 0" }}
                                            name="accessCode"
                                            required
                                            fullWidth
                                            id="accessCode"
                                            label="Access Code"
                                            onChange={handleInput}
                                        // autoFocus
                                        />
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        <h4 style={{ color: "red", fontWeight: "Bold" }}>{msg}</h4>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled
                        >
                            Register
                        </Button>

                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container >
        </ThemeProvider >
    );
}