import { useEffect } from "react";
import { useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom"
import "./Carousel.css";
import './slider.css'

import a from '../assets/art (1).jpg'
import b from '../assets/art (2).jpg'
import c from '../assets/art (3).jpg'
import e from '../assets/aaart.jpg'
import main from "../assets/main (1).jpeg"
import math1 from "../assets/ol1.jpg"
const images = [
    {
        image: a,
        title: "Brazil",
    },
    {
        image: b,
        title: "China",
    },
    {
        image: c,
        title: "France",
    },
    {
        image: e,
        title: "Japan",
    },
    {
        image: math1,
        title: "Japan",
    },
];

function Carousel() {
    const [current, setCurrent] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    const navigate = useNavigate()
    let timeOut = null;

    useEffect(() => {
        timeOut =
            autoPlay &&
            setTimeout(() => {
                slideRight();
            }, 2500);
    });

    const slideRight = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1);
    };

    const slideLeft = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    };
    const handleImageClick = (image) => {
        if (image.title === "Brazil") {
            navigate('/about')
        }
    }
    return (
        <div
            className="carousel"
            onMouseEnter={() => {
                setAutoPlay(false);
                clearTimeout(timeOut);
            }}
            onMouseLeave={() => {
                setAutoPlay(true);
            }}
        >
            <div className="carousel_wrapper">
                {images.map((image, index) => {
                    return (
                        /* (condition) ? true : false */

                        <div
                            onClick={() => handleImageClick(image)}
                            key={index}
                            className={
                                index == current
                                    ? "carousel_card carousel_card-active"
                                    : "carousel_card"
                            }
                        >
                            <img className="card_image" style={{ cursor: "pointer" }} src={image.image} alt="" />
                        </div>
                    );
                })}
                <FaArrowAltCircleLeft className='left-arrow' onClick={slideLeft} />
                <FaArrowAltCircleRight className='right-arrow' onClick={slideRight} />

                {/* <div className="carousel_arrow_left" onClick={slideLeft}>
                    &lsaquo;
                </div>
                <div className="carousel_arrow_right" onClick={slideRight}>
                    &rsaquo;
                </div> */}
                <div className="carousel_pagination" id='rounds'>
                    {images.map((_, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    index == current
                                        ? "pagination_dot pagination_dot-active"
                                        : "pagination_dot"
                                }
                                onClick={() => setCurrent(index)}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Carousel;