import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import math1 from "../assets/math1.jpg"
import sm1 from "../assets/sm1 (1).jpg"
import sm2 from "../assets/sm1 (2).jpg"
import sm3 from "../assets/sm1 (3).jpg"
import sm4 from "../assets/sm7 (1).jpg"
import sm5 from "../assets/sm1 (5).jpg"
import sm6 from "../assets/sm6.jpg"
import sm7 from "../assets/country/abroad (1).jpg"
import sm8 from "../assets/country/abroad (3).jpg"
import sm9 from "../assets/country/abroad (4).jpg"
import { Button, CardActionArea, CardActions } from '@mui/material';
import "./media.css"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ResponsiveGrid() {
    const cont = [
        { image: sm1, id: 1 },
        { image: sm2, id: 10 },
        { image: sm3, id: 11 },
        { image: sm4, id: 122 },
        { image: sm5, id: 1123 },
        { image: sm6, id: 11723 },
        { image: sm7, id: 121723 },
        { image: sm8, id: 114723 },
        { image: sm9, id: 104723 },
    ]

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {cont.map((i, j) => {
                    return (
                        <Grid item xs={2} sm={4} md={4} >
                            <Card sx={{ maxWidth: 345 }}>
                                <CardActionArea>
                                    <CardMedia
                                        className='imagesAll'
                                        component="img"
                                        height="100%"
                                        image={i.image}
                                        alt="green iguana"
                                    />

                                </CardActionArea>

                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
}