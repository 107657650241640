import React from 'react'
import TestimonialsCard from "./TestimonialCard"
import Grid from '@mui/material/Grid';
import { dataReview } from "../context/testimonialContext"

function Testimonials() {
    const [spacing, setSpacing] = React.useState(2);
    return (
        <div style={{ marginBottom: "20px" }}>
            <div style={{ marginTop: "20px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>

                <h2 style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>Testimonials</h2>
            </div>

            <div style={{ margin: "5px 45px", display: "flex", justifyContent: "space-around" }}>
                <Grid sx={{ flexGrow: 1, my: 3 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={spacing}>
                            {dataReview.map((value, ind) => (
                                <Grid key={ind} item>
                                    <TestimonialsCard {...value} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                </Grid>


                {/* <TestimonialsCard />
                <TestimonialsCard />
                <TestimonialsCard /> */}
            </div>
        </div>
    )
}

export default Testimonials