import kg1 from "../assets/kg2.jpg"
import kg2 from "../assets/kg4.jpeg"
import kg3 from "../assets/kg3.jpg"
export const dataReview = [
    {
        name: "Aktilek",
        img: kg1,
        text: "Participating in the Math Olympiad has been a truly transformative experience for me. The challenges presented in this competition pushed me beyond my comfort zone and allowed me to discover the beauty and excitement of mathematics in a way I had never imagined."
    },
    {
        name: "Eliza",
        img: kg2,
        text: "I highly recommend the Bilimtech Olympiads to anyone who loves STEM and wants to take their skills to the next level. It's not just a competition; it's a celebration of Science, a chance to connect with like-minded individuals, and an opportunity to discover the limitless possibilities that the world of STEM has to offer"
    },
    {
        name: "Kurmanzhan",
        img: kg3,
        text: "I am deeply grateful for the opportunity to be a part of this incredible journey. The Math Olympiad has not only honed my mathematical skills but has also instilled in me a sense of perseverance, discipline, and a passion for lifelong learning. "
    },

]