import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import abroad1 from "../assets/country/abroad (1).jpg"
import abroad2 from "../assets/country/abroad (2).jpg"
import abroad3 from "../assets/country/abroad (3).jpg"
import abroad4 from "../assets/country/abroad (4).jpg"
import abroad5 from "../assets/country/abroad (5).jpg"
import abroad6 from "../assets/country/abroad (6).jpg"
import abroad7 from "../assets/country/abroad (7).jpg"
import abroad9 from "../assets/country/abroad (9).jpg"
import abroad10 from "../assets/country/abroad (10).jpg"
import abroad11 from "../assets/country/abroad (11).jpg"
import abroad from "../assets/intern.jpg"

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ResponsiveGrid() {
    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState('');

    const handleClickOpen = (imgObj) => {
        setOpen(true);
        setItem(imgObj)
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ flexGrow: 1 }} style={{ padding: "15px" }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {itemData.map((item, index) => (
                    <Grid item xs={2} sm={4} md={4} key={index}>
                        <ImageListItem key={item.img}>
                            <img
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                                onClick={() => handleClickOpen(item)}
                            />
                        </ImageListItem>
                    </Grid>
                ))}
            </Grid>
            <div>

                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 4,
                            fontSize: '2rem',
                            top: 2,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon onClick={handleClose} />
                    </IconButton>
                    <DialogContent dividers>
                        <img
                            src={`${item.img}`}
                            // srcSet={`${item.img}`}
                            alt={item.title}
                            loading="lazy"
                            style={{ marginTop: "20px", maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
                        />

                    </DialogContent>

                </BootstrapDialog>
            </div>
        </Box>
    );
}



const itemData = [
    {
        img: abroad1,
        title: 'Breakfast',
    },
    {
        img: abroad2,
        title: 'Burger',
    },
    {
        img: abroad3,
        title: 'Camera',
    },
    {
        img: abroad4,
        title: 'Coffee',
    },
    {
        img: abroad5,
        title: 'Hats',
    },
    {
        img: abroad6,
        title: 'Honey',
    },
    {
        img: abroad7,
        title: 'Basketball',
    },
    {
        img: abroad,
        title: 'Fern',
    },
    {
        img: abroad9,
        title: 'Mushrooms',
    },
    {
        img: abroad10,
        title: 'Tomato basil',
    },
    {
        img: abroad11,
        title: 'Sea star',
    },

];